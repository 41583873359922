<template>
  <div>
    <c-table
      ref="table2"
      id="meeting"
      title="회의 목록"
      :columns="grid2.columns"
      :gridHeight="grid2.height"
      :data="assessPlan.conferences"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :isFullScreen="false"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="ramAssessmentConferenceId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" :showLoading="false" label="추가" icon="add" 
          @btnClicked="addConference" />
          <c-btn 
            v-if="editable&&!disabled && assessPlan.conferences && assessPlan.conferences.length>0" 
            :disabled="disabled"
            label="삭제" 
            icon="remove" 
            @btnClicked="deleteConference" />
          <c-btn 
            v-if="editable&&!disabled && assessPlan.conferences && assessPlan.conferences.length>0" 
            :disabled="disabled"
            :isSubmit="isSaveConference"
            :url="saveConferenceUrl"
            :param="assessPlan.conferences"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveAssessConference"
            @btnCallback="saveConferenceCallback" />
        </q-btn-group>
      </template>
    </c-table>
    <!-- 관련자료 -->
    <c-table
      class="q-mt-sm"
      ref="table3"
      id="docu"
      title="관련자료"
      :columns="grid3.columns"
      :gridHeight="grid3.height"
      :data="assessPlan.relatedDocuments"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :isFullScreen="false"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="ramRelatedDocumentId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 기타-추가 -->
          <c-btn v-if="editable && !disabled" label="기타-추가" icon="add" @btnClicked="addEtc"/>
          <c-btn v-if="editable && !disabled" label="추가" icon="add" @btnClicked="addRelateDocument"/>
          <c-btn 
            v-if="editable&&!disabled && assessPlan.relatedDocuments && assessPlan.relatedDocuments.length>0" 
            :disabled="disabled"
            label="삭제" 
            icon="remove" 
            @btnClicked="deleteRelateDocument" />
          <c-btn 
            v-if="editable&&!disabled && assessPlan.relatedDocuments && assessPlan.relatedDocuments.length>0" 
            :disabled="disabled"
            :isSubmit="isSaveRelateDocument"
            :url="saveRelateDocumentUrl"
            :param="assessPlan.relatedDocuments"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveRelateDocument"
            @btnCallback="saveRelateDocumentCallback" />
        </q-btn-group>
      </template>
    </c-table>
    <!-- 관련 아차사고 목록 -->
    <c-table
      class="q-mt-sm"
      id="near"
      ref="neartable"
      title="관련 아차사고 목록"
      :columns="gridNear.columns"
      :data="gridNear.data"
      :gridHeight="gridNear.height"
      :filtering="false"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      selection="multiple"
      rowKey="iimNearAccidentId"
      @linkClick="nearLinkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addNearTask" />
          <c-btn v-show="editable && gridNear.data && gridNear.data.length>0" label="삭제" icon="remove" @btnClicked="deleteNearTask" />
        </q-btn-group>
      </template>
    </c-table>
    <!-- 관련 순회점검 목록 -->
    <c-table
      class="q-mt-sm pb-3"
      ref="patroltable"
      id="patrol"
      title="관련 순회점검 목록"
      :columns="gridPatrol.columns"
      :data="gridPatrol.data"
      :gridHeight="gridPatrol.height"
      :filtering="false"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      selection="multiple"
      rowKey="saiPatrolId"
      @linkClick="patrolLinkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addPatrolTask" />
          <c-btn v-if="editable && gridPatrol.data && gridPatrol.data.length>0" label="삭제" icon="remove" @btnClicked="deletePatrolTask" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'riskAnalysisTeam',
  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        ramStepCd: '',
        teams: [], // 평가팀 목록
        conferences: [], // 회의 목록
        relatedDocuments: [], // 관련자료 목록
      }),
    },
    vendorFlag: {
      type: Boolean,
      default: () => ({
        flag: false,
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    disabled2: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
  },
  data() {
    return {
      editable: true,
      grid2: {
        columns: [
          {
            name: 'conferenceName',
            field: 'conferenceName',
            // 회의명
            label: '회의명',
            align: 'left',
            style: 'width:25%',
            type: 'text',
            sortable: false,
            required: true,
          },
          {
            name: 'conferenceDate',
            field: 'conferenceDate',
            // 회의일
            label: '회의일',
            align: 'center',
            style: 'width:10%',
            type: 'date',
            sortable: false,
            required: true,
          },
          {
            name: 'conferenceContents',
            field: 'conferenceContents',
            // 회의내용
            label: '회의내용',
            align: 'left',
            style: 'width:45%',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'attendees',
            field: 'attendees',
            // 참석자
            label: '참석자',
            align: 'center',
            style: 'width:10%',
            type: 'text',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '첨부파일',
            align: 'center',
            style: 'width:10%',
            type: 'attach',
            taskClassCd: 'RSA_CONFERENCE',
            keyText: 'ramAssessmentConferenceId',
            sortable: false,
          },
        ],
        data: [],
        height: 'auto'
      },
      grid3: {
        columns: [
          {
            name: 'ramDocumentTypeName',
            field: 'ramDocumentTypeName',
            label: '분류',
            align: 'center',
            style: 'width:10%',
            sortable: false,
          },
          {
            name: 'documentTitle',
            field: 'documentTitle',
            description: 'documentSubTitle',
            label: '제목',
            align: 'left',
            style: 'width:40%',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            // 자료명
            label: '자료명',
            align: 'left',
            style: 'width:40%',
            type: 'text',
            sortable: false,
            required: true,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '첨부파일',
            align: 'center',
            style: 'width:10%',
            type: 'attach',
            taskClassCd: 'RSA_RELATE_INFO',
            keyText: 'ramRelatedDocumentId',
            sortable: false,
          },
        ],
        height: 'auto'
      },
      gridNear: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "사업장",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearAccidentNo",
            field: "iimNearAccidentNo",
            // 아차사고번호
            label: "아차사고번호",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearAccidentName",
            field: "iimNearAccidentName",
            // 아차사고명
            label: "아차사고명",
            style: 'width:320px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "processName",
            field: "processName",
            label: "공정",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "iimStatusNearName",
            field: "iimStatusNearName",
            // 진행단계
            style: 'width:100px',
            label: "진행상태",
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearKindName",
            field: "iimNearKindName",
            // 아차사고유형
            style: 'width:80px',
            label: "아차사고유형",
            align: "center",
            sortable: true,
          },
          {
            name: "iimGradeName",
            field: "iimGradeName",
            // 아차사고등급
            label: "아차사고등급",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "occurrenceLocation",
            field: "occurrenceLocation",
            // 발생가능장소
            label: "발생가능장소",
            style: 'width:100px',
            align: "left",
            sortable: true,
          },
          {
            name: "occurrenceDt",
            field: "occurrenceDt",
            // 발생일
            style: 'width:100px',
            label: "발생일",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
        height: 'auto'
      },
      gridPatrol: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '부서',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'sopMapId',
            field: 'sopMapId',
            // 순회장소
            label: '순회장소',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolName',
            field: 'patrolName',
            // 순회명
            label: '순회명',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'patrolDate',
            field: 'patrolDate',
            // 순회일
            label: '순회일',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolCompleteFlagName',
            field: 'patrolCompleteFlagName',
            label: '진행상태',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
        height: 'auto'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      conferenceListUrl: '',
      saveConferenceUrl: '',
      deleteConferenceUrl: '',
      relateDocumentListUrl: '',
      saveRelateDocumentUrl: '',
      deleteRelateDocumentUrl: '',
      nearListUrl: '',
      patrolListUrl: '',
      task: {
        insertUrl: '',
        deleteUrl: '',
      },
      isSaveConference: false,
      isSaveRelateDocument: false,
    };
  },
  computed: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.conferenceListUrl = selectConfig.ram.assessConference.list.url;
      this.relateDocumentListUrl = selectConfig.ram.relatedDocument.list.url;
      this.saveConferenceUrl = transactionConfig.ram.assessConference.update.url;
      this.saveRelateDocumentUrl = transactionConfig.ram.relatedDocument.update.url;
      this.deleteConferenceUrl = transactionConfig.ram.assessConference.delete.url;
      this.deleteRelateDocumentUrl = transactionConfig.ram.relatedDocument.delete.url;
      this.nearListUrl = selectConfig.sop.iim.accident.near.list.url;
      this.patrolListUrl = selectConfig.sai.patrol.list.url;
      this.task.insertUrl = transactionConfig.ram.assessPlan.task.insert.url;
      this.task.deleteUrl = transactionConfig.ram.assessPlan.task.delete.url;
      // code setting
      // list setting
      this.getNearList();
      this.getPatrolList();
    },
    getConferences() {
      this.$http.url = this.conferenceListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.assessPlan.conferences = _result.data
      },);
    },
    getRelateDocuments() {
      this.$http.url = this.relateDocumentListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.assessPlan.relatedDocuments = _result.data
      },);
    },
    getNearList() {
      this.$http.url = this.nearListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      };
      this.$http.request((_result) => {
        this.gridNear.data = _result.data;
      },);
    },
    getPatrolList() {
      this.$http.url = this.patrolListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      };
      this.$http.request((_result) => {
        this.gridPatrol.data = _result.data;
      },);
    },
    addConference() {
      this.assessPlan.conferences.splice(0, 0, {
        ramAssessmentConferenceId: uid(),
        conferenceName: '',
        conferenceDate: this.$comm.getToday(),
        conferenceContents: '',
        attendees: '',
        editFlag: 'C', regUserId: this.$store.getters.user.userId,
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      })
    },
    deleteConference() {
      let selectData = this.$refs['table2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.deleteConferenceUrl;
        this.$http.type = 'DELETE';
        this.$http.param = {
          data: Object.values(selectData),
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          
          this.$_.forEach(selectData, item => {
            this.assessPlan.conferences = this.$_.reject(this.assessPlan.conferences, item);
          })
          this.$refs['table2'].$refs['compo-table'].clearSelection();
          // this.getConferences();
        },);
      }
    },
    addRelateDocument() {
      this.popupOptions.title = '관련자료 검색'; // 관련자료 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskRelationDocu.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRelateDocumentPopup;
    },
    closeRelateDocumentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.assessPlan.relatedDocuments, { documentId: item.documentId, ramDocumentTypeCd: item.ramDocumentTypeCd }) === -1) {
            this.assessPlan.relatedDocuments.splice(0, 0, { 
              ramRelatedDocumentId: uid(),  // 관련자료 일련번호
              ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId,  // 평가 일련번호
              ramDocumentTypeCd: item.ramDocumentTypeCd,  // 문서 분류 코드
              ramDocumentTypeName: item.ramDocumentTypeName,  // 문서 분류 코드
              documentId: item.documentId,  // 문서 일련번호
              documentTitle: item.documentTitle,  // 문서 제목
              documentSubTitle: item.documentSubTitle,  // 문서 부제목
              materialName: item.documentTitle,  // 자료명
              editFlag: 'C', regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    addEtc() {
      if (!this.assessPlan.relatedDocuments) this.assessPlan.relatedDocuments = [];
      this.assessPlan.relatedDocuments.splice(0, 0, { 
        ramRelatedDocumentId: uid(),  // 관련자료 일련번호
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId,  // 평가 일련번호
        ramDocumentTypeCd: 'RDT0000020',  // 문서 분류 코드
        ramDocumentTypeName: '기타',  // 문서 분류 코드
        documentId: '',  // 문서 일련번호
        documentTitle: '',  // 문서 제목
        documentSubTitle: '',  // 문서 부제목
        materialName: '',  // 자료명
        editFlag: 'C', regUserId: this.$store.getters.user.userId,
      });
    },
    saveAssessConference() {
      if (this.$comm.validTable(this.grid2.columns, this.assessPlan.conferences)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.assessPlan.conferences, item => {
              item.chgUserId = this.$store.getters.user.userId
            })

            this.isSaveConference = !this.isSaveConference
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveConferenceCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getConferences();
    },
    saveRelateDocument() {
      if (this.$comm.validTable(this.grid3.columns, this.assessPlan.relatedDocuments)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSaveRelateDocument = !this.isSaveRelateDocument
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRelateDocumentCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getRelateDocuments();
    },
    deleteRelateDocument() {
      let selectData = this.$refs['table3'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.deleteRelateDocumentUrl;
        this.$http.type = 'DELETE';
        this.$http.param = {
          data: Object.values(selectData),
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          
          this.$_.forEach(selectData, item => {
            this.assessPlan.relatedDocuments = this.$_.reject(this.assessPlan.relatedDocuments, item);
          })
          this.$refs['table3'].$refs['compo-table'].clearSelection();
          // this.getRelateDocuments();
        },);
      }
    },
    nearLinkClick(row) {
      this.popupOptions.title = "아차사고 상세"; // 아차사고 상세
      this.popupOptions.param = {
        iimNearAccidentId: row ? row.iimNearAccidentId : '',
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/iim/near/nearAccidentDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeNearPopup;
    },
    closeNearPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getNearList();
    },
    deleteNearTask() {
      let selectData = this.$refs['neartable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '제외하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.task.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(this.$_.map(selectData, (item) => {
                return {
                  ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId,
                  iimNearAccidentId: item.iimNearAccidentId,
                }
              }))
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$refs['neartable'].$refs['compo-table'].clearSelection();
              this.getNearList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addNearTask() {
      this.popupOptions.title = '아차사고 검색'; // 아차사고 검색
      this.popupOptions.param = {
        type: 'multiple',
        accidentKind: 'NEAR',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/iim/accidentPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeNearTaskPopup;
    },
    closeNearTaskPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let insertList = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.gridNear.data, { iimNearAccidentId: item.iimNearAccidentId }) === -1) {
            insertList.push({
              ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId,
              iimNearAccidentId: item.iimNearAccidentId,
            });
          }
        })

        this.$http.url = this.task.insertUrl;
        this.$http.type = 'POST';
        this.$http.param = insertList
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getNearList();
        },);
      }
    },
    patrolLinkClick(row) {
      this.popupOptions.title = "순회점검 상세"; // 순회점검 상세
      this.popupOptions.param = {
        saiPatrolId: row.saiPatrolId
      };
      this.popupOptions.target = () => import(`${"@/pages/sai/patrol/patrolDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePatrolPopup;
    },
    closePatrolPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getPatrolList();
    },
    deletePatrolTask() {
      let selectData = this.$refs['patroltable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '제외하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.task.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(this.$_.map(selectData, (item) => {
                return {
                  ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId,
                  saiPatrolId: item.saiPatrolId,
                }
              }))
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$refs['patroltable'].$refs['compo-table'].clearSelection();
              this.getPatrolList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addPatrolTask() {
      this.popupOptions.title = '순회점검 검색';
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/patrol/patrolPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePatrolTaskPopup;
    },
    closePatrolTaskPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let insertList = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.gridPatrol.data, { saiPatrolId: item.saiPatrolId }) === -1) {
            insertList.push({
              ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId,
              saiPatrolId: item.saiPatrolId,
            });
          }
        })

        this.$http.url = this.task.insertUrl;
        this.$http.type = 'POST';
        this.$http.param = insertList
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getPatrolList();
        },);
      }
    },
  }
};
</script>
<style lang="sass">
.default-table 
  thead 
    tr 
      th 
        padding: 0 !important
</style>